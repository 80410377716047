import React from "react";
import logo from "../../assest/logo.svg";
import classes from "./Navbar.module.css";

const Navbar = () => {
  return (
    <div className="container">
      <nav className="navbar navbar-expand-lg">
        <a className="navbar-brand" href="#">
          <img src={logo} />
        </a>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div
          className="collapse navbar-collapse justify-content-end align-items-center"
          id="navbarSupportedContent"
        >
          <ul className="navbar-nav mr-auto">
            <li className="nav-item">
              <a href="#home" className={classes.Link}>
                Home
              </a>
            </li>
            <li className="nav-item">
              <a href="#services" className={classes.Link}>
                Services
              </a>
            </li>
            <li className="nav-item">
              <a href="#about" className={classes.Link}>
                About Us
              </a>
            </li>
            <li className="nav-item">
              <a href="#contact" className={classes.Link}>
                Contact Us
              </a>
            </li>
          </ul>
        </div>
      </nav>
    </div>
  );
};

export default Navbar;
