import React from "react";
import Navbar from "../Navbar/Navbar";
import classes from "./Home.module.css";
import sc from "../../assest/sc.png";
import deltaz from "../../assest/deltaz.png";
import chartely from "../../assest/chartely.png";
import lprs from "../../assest/lprs.png";
import hcsv from "../../assest/hcsv.png";
import CustomCarousel from "../Carousel/Carousel";
import ITConsultingImage from "../../assest/itconst.svg";
import IOTImage from "../../assest/iot.svg";
import MobileDevImage from "../../assest/mad.svg";
import WebDevImage from "../../assest/wbd.svg";
import ContactUs from "../ContactUs/ContactUs";
import Footer from "../Footer";

// import team from "../../assest/team.svg";
// import whyUs from "../../assest/whyUs.svg";
// import ContactUs from "../ContactUs/ContactUs";

const Home = () => {
  const topSubTitleImages = [lprs, sc, deltaz, chartely, hcsv];

  const carouselContent = [
    { title: "IT Consulting", image: ITConsultingImage },
    { title: "Internet of Things", image: IOTImage },
    { title: "Mobile Development", image: MobileDevImage },
    { title: "Web Development", image: WebDevImage },
  ];

  const navigate = (path) => {
    window.location.href = path;
  };

  return (
    <div className="container-fluid p-0">
      {/* <navbar /> */}
      <section>
        <Navbar />
      </section>
      {/* top section */}
      <section id="home">
        <div className="container">
          <div className="row mt-3">
            <div className="col-lg-5 mt-1">
              <div className={classes.PageTitle}>
                Leveraging tech to drive a better IT experience
              </div>
              <div className={classes.PageSubTitle}>
                Discover the power of technology with VoltHertz.{" "}
              </div>
              <div>
                <button
                  className={classes.ContactBtn + " btn btn-dark"}
                  onClick={() => navigate("/#contact")}
                >
                  Contact Us
                </button>
              </div>
              <div
                className={
                  classes.PageSubtitleImageWrapper + " d-flex flex-wrap"
                }
              >
                {topSubTitleImages.map((item) => (
                  <div className={classes.PageSubtitleImageConatiner}>
                    <img
                      src={item}
                      alt="customers"
                      className={classes.PageSubtitleImage}
                    />
                  </div>
                ))}
              </div>
            </div>
            <div className="col-lg-7 mt-1">
              <div className={classes.TopImageContainer}>
                <div className={classes.TopImageText}>
                  <div className={classes.TopImageTextTitle}>
                    Transforming Business with Technology
                  </div>
                  <div className={classes.TopImageTextSubTitle}>
                    We specialize in delivering cutting-edge technology
                    solutions to businesses of all sizes, helping them optimize
                    their operations, enhance productivity, and achieve their
                    goals.
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row mt-2">
            <div className="col-lg-5 mt-1">
              <div
                className={
                  classes.TopTwoimageContainer + ` ${classes.InnovateCont}`
                }
              >
                <div className={classes.TopTwoimageText}>
                  <div className={classes.TopTwoImageTextTitle}>
                    Innovate, Connect, Inspire
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-7">
              <div className="row">
                <div className="col-md-6 mt-1">
                  <div
                    className={
                      classes.TopTwoimageContainer + ` ${classes.SmartCont}`
                    }
                  >
                    <div className={classes.TopTwoimageText}>
                      <div className={classes.TopTwoImageTextTitle}>
                        Smart, fast, affordable
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 mt-1">
                  <div
                    className={
                      classes.TopTwoimageContainer + ` ${classes.SecureCont}`
                    }
                  >
                    <div className={classes.TopTwoimageText}>
                      <div className={classes.TopTwoImageTextTitle}>
                        Secure. Automated. Global.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* services section */}
      <section id="services" className={classes.ServicesSection + " mt-5"}>
        <div className="container">
          <div className="row">
            <div className="col-md-7 d-flex flex-column justify-content-center">
              <div className={classes.ServiceTitle}>Services we Offer</div>
            </div>
            <div className="col-md-5">
              <div className={classes.ServiceSubTitle}>
                It’s always best to have a technical consultant in a technology
                start-up. IT as a service can help your start-up, spin-off or
                new product get the proper technical foundation.
              </div>
              <div>
                <button
                  className={classes.ContactBtn + " btn btn-light"}
                  onClick={() => navigate("/#contact")}
                >
                  Contact Us
                </button>
              </div>
            </div>
          </div>
          <div className="row mt-5">
            <CustomCarousel>
              {carouselContent.map((item) => (
                <div
                  className={classes.CaruImageConatainer}
                  style={{
                    backgroundImage: "url(" + item.image + ")",
                    border: "1px soild red",
                  }}
                >
                  <div className={classes.CaruImageTitle}>{item.title}</div>
                </div>
              ))}
            </CustomCarousel>
          </div>
        </div>
      </section>

      {/* know our team */}
      <section id="about" className="mt-5">
        <div className="container">
          <div className="row">
            <div className="col-lg-7 d-flex flex-column justify-content-center">
              <div>
                <div className={classes.KnowTitle}>Know our Team</div>
                <div className={classes.KnowSubTitle}>
                  The team at voltHertz has a decade of experience at building
                  tech. we understand the evolving demands of the digital
                  landscape and the critical role that technology plays in
                  today's business environment.
                </div>
                <div className={classes.KnowSubTitle}>
                  Our team of highly skilled professionals brings a wealth of
                  experience and expertise across various domains to deliver
                  innovative and tailored solutions that align with your unique
                  business requirements.
                </div>
              </div>
            </div>
            <div className="col-lg-5">
              <div className={classes.KnowImageContainer}></div>
            </div>
          </div>
        </div>
      </section>

      {/* why us */}
      <section className={classes.WhySection + " mt-5"}>
        <div className={classes.WhyTitle}>Why Choose Us?</div>
        <div className="container">
          <div className="row ">
            <div className="col-lg-7 d-flex justify-content-center align-items-center">
              <div className={classes.WhyImageContainer}></div>
            </div>
            <div className="col-lg-5 mt-4">
              <div>
                <div className={classes.WhyPointTitle}>Experience:</div>
                <div className={classes.WhySubTitle}>
                  Our expertise allows us to understand your unique business
                  challenges and provide tailored solutions that drive results.
                </div>
                <div className={classes.WhyPointTitle}>
                  Quality and Reliability:
                </div>
                <div className={classes.WhySubTitle}>
                  Our meticulous attention to detail, rigorous testing
                  processes, and adherence to best practices ensure that our
                  solutions are reliable, efficient, and secure.
                </div>
                <div className={classes.WhyPointTitle}>
                  Client-Centric Approach:
                </div>
                <div className={classes.WhySubTitle}>
                  We listen attentively to your needs, collaborate closely
                  throughout the project lifecycle, and provide exceptional
                  customer service. Your success is our success.
                </div>
                <div className={classes.WhyPointTitle}>Innovation:</div>
                <div className={classes.WhySubTitle}>
                  We stay at the forefront of technological advancements to
                  bring you innovative solutions that give you a competitive
                  edge.
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* contact us */}
      <section id="contact" className="mt-5 mb-5">
        <ContactUs />
      </section>
      {/* <footer /> */}
      <section className={classes.FooterSection}>
        <Footer />
      </section>
    </div>
  );
};

export default Home;
