import React from "react";
import logo from "../../assest/logo.svg";

const Footer = () => {
  return (
    <div class="container">
      <footer class="d-flex flex-wrap justify-content-between align-items-center py-3">
        <p class="col-md-4 mb-0 text-white">© 2023 VoltHertz</p>
        <a href="#" class="col-md-4 d-flex justify-content-center">
          <div className="border border-white p-1 bg-white rounded">
            <img src={logo} />
          </div>
        </a>

        <ul class="nav col-md-4 justify-content-end">
          <li class="nav-item">
            <a href="#home" className="nav-link px-2 text-white">
              Home
            </a>
          </li>
          <li class="nav-item">
            <a href="#services" className="nav-link px-2 text-white">
              Services
            </a>
          </li>

          <li class="nav-item">
            <a href="#about" className="nav-link px-2 text-white">
              About Us
            </a>
          </li>
          <li class="nav-item">
            <a href="#contact" className="nav-link px-2d text-white">
              Contact Us
            </a>
          </li>
        </ul>
      </footer>
    </div>
  );
};

export default Footer;
