import React from "react";
import classes from "./ContactUs.module.css";
import letterSendImage from "../../assest/lettersend.svg";
import Blob1Image from "../../assest/blob1.svg";
import Blob2Image from "../../assest/blob2.svg";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as yup from "yup";
import axios from "axios";
import { toast } from "react-toastify";

const validatetion = yup.object().shape({
  firstName: yup.string().required("This is a required field"),
  lastName: yup.string().required("This is a required field"),
  email: yup.string().email().required("This is a required field"),
  phone: yup.string().required("This is a required field"),
  message: yup.string().required("This is a required field"),
});

const ContactUs = () => {
  const handleSubmit = (values, resetForm) => {
    const payload = {
      blocks: [
        {
          type: "section",
          text: {
            type: "mrkdwn",
            text: "First Name : " + values.firstName,
          },
        },
        {
          type: "section",
          text: {
            type: "mrkdwn",
            text: "Last Name : " + values.lastName,
          },
        },
        {
          type: "section",
          text: {
            type: "mrkdwn",
            text: "Phone : " + values.phone,
          },
        },
        {
          type: "section",
          text: {
            type: "mrkdwn",
            text: "Email : " + values.email,
          },
        },
        {
          type: "section",
          text: {
            type: "mrkdwn",
            text: "Message : " + values.message,
          },
        },
      ],
    };

    const url =
      "https://hooks.slack.com/services/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";

    const headers = {
      "Content-type": "text/plain;charset=UTF-8",
    };

    axios
      .post(url, payload, { headers })
      .then((res) => {
        toast.success("Message Sent!");
        resetForm();
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message || err.message);
      });
  };

  return (
    <div>
      <div className="container overflow-hidden">
        <div className="row">
          <div className="col-lg-4">
            <div className={classes.LeftDiv}>
              <div>
                <div className={classes.Title}>Contact Us</div>
                <label className={classes.TSubTitle}>
                  Say something to start chat!
                </label>
                <div className={classes.SubWrapper}>
                  <p className={classes.Subtitle}>
                    <i
                      className={`${classes.Icon} fa-solid fa-phone-volume`}
                    ></i>
                    +65-98940506
                  </p>
                  <p className={classes.Subtitle}>
                    <i
                      className={`${classes.Icon} fa-solid fa-phone-volume`}
                    ></i>
                    +91-9566938370
                  </p>
                  <p className={classes.Subtitle}>
                    <i
                      className={`fa-solid fa-envelope`}
                      style={{ marginRight: "0.7rem" }}
                    ></i>
                    info@volthertz.com
                  </p>
                  <p className={classes.Subtitle}>
                    <i
                      className={`fa-solid fa-location-dot`}
                      style={{ marginRight: "0.9rem" }}
                    ></i>
                    Jurong West, Singapore
                  </p>
                  <p className={classes.Subtitle}>
                    <i
                      className={`fa-solid fa-location-dot`}
                      style={{ marginRight: "0.9rem" }}
                    ></i>
                    Noida, India
                  </p>
                  <p className={classes.Subtitle}>
                    <i
                      className={`fa-solid fa-location-dot`}
                      style={{ marginRight: "0.9rem" }}
                    ></i>
                    Skive, Denmark
                  </p>
                </div>
              </div>
              <div className={classes.Blob1}>
                <img src={Blob1Image} className={classes.BlobImage} />
              </div>
              <div className={classes.Blob2}>
                <img src={Blob2Image} className={classes.BlobImage} />
              </div>
            </div>
          </div>
          <div className="col-lg-1"></div>
          <div className="col-lg-7 mt-4 p-3">
            <Formik
              initialValues={{
                firstName: "",
                lastName: "",
                email: "",
                phone: "",
                message: "",
              }}
              onSubmit={(values, { resetForm }) =>
                handleSubmit(values, resetForm)
              }
              validationSchema={validatetion}
            >
              <Form>
                <div className="row">
                  <div className="col-md-6 mb-3">
                    <label htmlFor="firstName" className="form-label">
                      First Name
                    </label>
                    <Field
                      name="firstName"
                      type="text"
                      className="form-control"
                    />
                    <ErrorMessage
                      name="firstName"
                      component="div"
                      className={classes.Error}
                    />
                  </div>
                  <div className="col-md-6 mb-3">
                    <label htmlFor="lastName" className="form-label">
                      Last Name
                    </label>
                    <Field
                      name="lastName"
                      type="text"
                      className="form-control"
                    />
                    <ErrorMessage
                      name="lastName"
                      component="div"
                      className={classes.Error}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6 mb-3">
                    <label htmlFor="email" className="form-label">
                      Email
                    </label>
                    <Field name="email" type="email" className="form-control" />
                    <ErrorMessage
                      name="email"
                      component="div"
                      className={classes.Error}
                    />
                  </div>
                  <div className="col-md-6 mb-3">
                    <label htmlFor="phone" className="form-label">
                      Phone
                    </label>
                    <Field name="phone" type="phone" className="form-control" />
                    <ErrorMessage
                      name="phone"
                      component="div"
                      className={classes.Error}
                    />
                  </div>
                </div>
                <div className="row mb-5">
                  <div className="col-md-12">
                    <div>
                      <label htmlFor="message" className="form-label">
                        Message
                      </label>
                      <Field
                        name="message"
                        type="message"
                        className="form-control"
                        as="textarea"
                        id="message"
                        rows="3"
                      />
                      <ErrorMessage
                        name="message"
                        component="div"
                        className={classes.Error}
                      />
                    </div>
                  </div>
                </div>
                <div className="row justify-content-center">
                  <button className={`${classes.Btn} col-md-auto`}>
                    Send Message
                  </button>
                </div>
              </Form>
            </Formik>
            <div className="d-flex justify-content-around">
              <img src={letterSendImage} />
              <div></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
