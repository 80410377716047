import React from "react";
import Navbar from "../Navbar/Navbar";
import classes from "./About.module.css";
import about from "../../assest/Idea.png";
const About = () => {
  return (
    <div className="container">
      <div>
        <Navbar />
        <div className={`${classes.MainContainer} row`}>
          <div className="col-md-6">
            <div className={classes.AboutBtn}>
              <h3>About Us</h3>
            </div>
            <div style={{ marginTop: "20vh", marginLeft: "1vw" }}>
              <h3 style={{ color: "white", marginBottom: "5vh" }}>The Team</h3>
              <label style={{ color: "white" }}>
                The team at voltHertz has a decade of experience <br />
                at building tech. we understand the evolving <br />
                demands of the digital landscape and the critical <br />
                role that technology plays in today's business environment.{" "}
                <br /> Our team of highly skilled professionals brings a wealth{" "}
                <br />
                of experience and expertise across various domains to deliver{" "}
                <br />
                innovative and tailored solutions that align with <br />
                your unique business requirements.
              </label>
            </div>
          </div>
          <div
            className="col-md-6"
            style={{
              position: "relative",
              backgroundImage: `url(${about})`,
              height: "83vh",
              borderRadius: "34px",
            }}
          >
            <div className={classes.ContentHeading}>
              <h3>Why Choose Us?</h3>
            </div>
            <div className={classes.Content}>
              <ul>
                <li>
                  Experience: Our expertise allows us to <br />
                  understand your unique business challenges and <br />
                  provide tailored solutions that drive results.
                </li>
                <li>
                  Quality and Reliability: Our meticulous attention to detail,
                  <br />
                  rigorous testing processes, and adherence to best practices
                  <br />
                  ensure that our solutions are reliable, efficient, and secure.
                </li>

                <li>
                  Client-Centric Approach: We listen attentively to your needs,
                  <br />
                  collaborate closely throughout the project lifecycle,
                  <br /> and provide exceptional customer service.
                  <br /> Your success is our success.
                </li>
                <li>
                  Innovation: We stay at the forefront of technological
                  <br />
                  advancements to bring you innovative solutions <br />
                  that give you a competitive edge.
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
